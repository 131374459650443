import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import UnstackSplash from './UnstackSplash';
import GoTo from './GoTo';
import Signup from './Signup';
import { useSelector } from 'react-redux';
import { selectIsInitialized, selectMustFinishSignup } from 'reducers/appReducer';
import { selectAccount, selectAccountEmail, selectIsLoggedIn } from 'reducers/accountReducer';
import { selectAllSites, selectHasSites } from 'reducers/sitesReducer';
import Login from './Login/Login';
import MFALoginVerify from './Login/MFALoginVerify';
import LoggedInRoutes from './LoggedInRoutes';
import PageNotFound from './PageNotFound';
import KlevuOnboarding from './KlevuOnboarding';
import Home from './Home';

const SitesLoadedRoutes: React.FC = () => {
  const isInitialized = useSelector(selectIsInitialized);
  const mustFinishSignup = useSelector(selectMustFinishSignup);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const account = useSelector(selectAccount);
  const accountEmail = useSelector(selectAccountEmail);
  const hasSites = useSelector(selectHasSites);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userId = searchParams.get('uid');
  const navigate = useNavigate();
  const posthog = usePostHog();
  const currentPath = useLocation().pathname;
  const sites = useSelector(selectAllSites);

  useEffect(() => {
    if ((mustFinishSignup || userId) && currentPath !== '/signup') {
      navigate('signup');
    }
  }, [mustFinishSignup, userId, currentPath, isLoggedIn, hasSites, sites]);

  useEffect(() => {
    if (account.isLoggedIn && posthog && accountEmail) {
      posthog.identify(accountEmail, { email: accountEmail, name: account?.name });
    }
  }, [account, accountEmail]);

  return (
    <>
      {!isInitialized && <UnstackSplash isLoading />}
      {isInitialized && (
        <Routes>
          <Route path="goto/*" element={<GoTo />} />
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
          <Route path="verify" element={<MFALoginVerify />} />
          <Route path="klevu" element={<KlevuOnboarding />} />

          {!hasSites && !isLoggedIn && <Route path="" element={<Login />} />}
          {hasSites && isLoggedIn && <Route path="/*" element={<Home />} />}
          {hasSites && isLoggedIn && <Route path=":urlSite/*" element={<LoggedInRoutes />} />}

          {/* Catch-all route for unmatched URLs */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </>
  );
};

export default React.memo(SitesLoadedRoutes);
