import React, { ChangeEvent, LegacyRef, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import Select from './Select';
import MarginField from './MarginField';
import CapitalizeSelect from './CapitalizeSelect';
import LetterSpacingField from './LetterSpacingField';
import ColorSelector from './StyleGuideEditor/ColorSelector';
import { FontOptions, ColorOptions, Heading } from './types';

import { ReactComponent as SvgIconAdd } from '../../../assets/images/icon-plus.svg';
import { ReactComponent as SvgIconTrash } from '../../../assets/images/icon-remove-header.svg';
import { ReactComponent as SvgIconDesktopThumbnail } from '../../../assets/images/icon-desktop-styleguide.svg';
import { ReactComponent as SvgIconMobileThumbnail } from '../../../assets/images/icon-mobile-styleguide.svg';

import styles from './StyleGuideEditor.module.scss';

export default React.memo(
  ({
    fontFamilyOptions,
    styleGuide,
    fontWeightOptions,
    handleChange,
    handleChangeEvent,
    darkBgColor,
    ...props
  }: Heading) => {
    const [activeBackground, setActiveBackground] = useState({
      header_1: 'light',
      header_2: 'light',
      header_3: 'light',
      header_4: 'light',
      header_5: 'light',
      header_6: 'light',
    });
    const [click, setClick] = useState(false);

    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);

    useEffect(() => {
      if (click) {
        if (!styleGuide.header_count || styleGuide.header_count === 4) {
          const element = myRef4.current;
          element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else if (styleGuide.header_count === 5) {
          const element = myRef5.current;
          element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        } else {
          const element = myRef6.current;
          element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    }, [styleGuide.header_count, click]);

    return (
      <>
        <header ref={props.scrollRef} className={styles.fontsStylesHeader}>
          <h1>Headings</h1>
          <small>Set the text styles used across your site.</small>
          {(!styleGuide.header_count || styleGuide.header_count < 6) && (
            <button
              onClick={() => {
                setClick(true);
                if (!styleGuide.header_count || styleGuide.header_count === 3) {
                  handleChange([], {
                    ...styleGuide.typography,
                    header_count: 4,
                    header_4: {
                      font_family_index: styleGuide.header_3_font_family_index,
                      font_weight: styleGuide.header_3_font_weight,
                      font_size: styleGuide.header_3_font_size,
                      color: styleGuide.header_3_color,
                      line_height: styleGuide.header_3_line_height,
                      text_transform: styleGuide.header_3_text_transform,
                      letter_spacing: styleGuide.header_3_letter_spacing,
                      margin: styleGuide.header_3_margin,
                      dark_bg_color: styleGuide.header_3_dark_bg_color,
                    },
                  });
                } else if (styleGuide.header_count === 4) {
                  handleChange([], {
                    ...styleGuide.typography,
                    header_count: 5,
                    header_5: {
                      font_family_index: styleGuide.header_4_font_family_index,
                      font_weight: styleGuide.header_4_font_weight,
                      font_size: styleGuide.header_4_font_size,
                      color: styleGuide.header_4_color,
                      line_height: styleGuide.header_4_line_height,
                      text_transform: styleGuide.header_4_text_transform,
                      letter_spacing: styleGuide.header_4_letter_spacing,
                      margin: styleGuide.header_4_margin,
                      dark_bg_color: styleGuide.header_4_dark_bg_color,
                    },
                  });
                } else {
                  handleChange([], {
                    ...styleGuide.typography,
                    header_count: 6,
                    header_6: {
                      font_family_index: styleGuide.header_5_font_family_index,
                      font_weight: styleGuide.header_5_font_weight,
                      font_size: styleGuide.header_5_font_size,
                      color: styleGuide.header_5_color,
                      line_height: styleGuide.header_5_line_height,
                      text_transform: styleGuide.header_5_text_transform,
                      letter_spacing: styleGuide.header_5_letter_spacing,
                      margin: styleGuide.header_5_margin,
                      dark_bg_color: styleGuide.header_5_dark_bg_color,
                    },
                  });
                }
              }}
              className="button button-primary button-icon"
            >
              <SvgIconAdd height={15} width={15} />
              <span>New Heading</span>
            </button>
          )}
        </header>

        {/* Header 1 */}
        <article className={styles.StyleGuideEditor}>
          <section className={styles.styleSection}>
            <div className={styles.styleSectionHeader}>
              <p>H1</p>
              <div className={styles.bgToggles}>
                <div className={styles.background}>
                  <span
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.header_1 === 'light',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, header_1: 'light' })}
                  >
                    Light
                  </span>
                  <span
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.header_1 === 'dark',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, header_1: 'dark' })}
                  >
                    Dark
                  </span>
                </div>

                <div className={styles.device}>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, header_1: 'light' })}
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.header_1 === 'light',
                    })}
                  >
                    <SvgIconDesktopThumbnail />
                  </span>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, header_1: 'mobile' })}
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.header_1 === 'mobile',
                    })}
                  >
                    <SvgIconMobileThumbnail />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`sriracha ${
                  activeBackground.header_1 === 'dark'
                    ? 'dark-bg h1'
                    : activeBackground.header_1 === 'mobile'
                    ? 'mobile h1'
                    : ''
                }`}
                style={{
                  padding: activeBackground.header_1 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                  backgroundColor: activeBackground.header_1 === 'dark' ? darkBgColor.code : 'inherit',
                }}
              >
                <h1>Page header</h1>
              </div>
            </div>
            <div className={styles.styleSectionOptions}>
              {activeBackground.header_1 === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.header_1_font_family_index]}
                      value={fontFamilyOptions[styleGuide.header_1_font_family_index]}
                      onChange={(option: FontOptions) => handleChange(['header_1', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight: FontOptions) => weight.value === Number(styleGuide.header_1_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight: FontOptions) => weight.value === Number(styleGuide.header_1_font_weight)
                      )}
                      onChange={(option: FontOptions) => handleChange(['header_1', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="header_1_font_size"
                      value={styleGuide.header_1_font_size}
                      min="0.8"
                      max="10"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_1', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="header_1_line_height"
                      value={styleGuide.header_1_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_1', 'line_height'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.header_1_text_transform}
                    handleChange={(value) => handleChange(['header_1', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="header_1_letter_spacing"
                    value={styleGuide.header_1_letter_spacing}
                    handleChange={(value) => handleChange(['header_1', 'letter_spacing'], value)}
                  />
                  <MarginField
                    name="header_1_margin"
                    value={styleGuide.header_1_margin}
                    handleChange={(value) => handleChange(['header_1', 'margin'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_1_color"
                      onChange={(value: ColorOptions) => handleChange(['header_1', 'color'], value)}
                      value={styleGuide.header_1_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['header_1', 'color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.header_1 === 'dark' ? (
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name="header_1_dark_bg_color"
                    onChange={(value: ColorOptions) => handleChange(['header_1', 'dark_bg_color'], value)}
                    value={styleGuide.header_1_dark_bg_color}
                    placeholder="Color"
                    handleColorSelection={(value: ColorOptions) => handleChange(['header_1', 'dark_bg_color'], value)}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="header_1_font_size_mobile"
                      value={styleGuide.header_1_font_size_mobile}
                      min="0.8"
                      max="10"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_1', 'mobile', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="header_1_line_height_mobile"
                      value={styleGuide.header_1_line_height_mobile || styleGuide.header_1_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_1', 'mobile', 'line_height'])}
                    />
                  </div>
                  <LetterSpacingField
                    name="header_1_letter_spacing_mobile"
                    value={styleGuide.header_1_letter_spacing_mobile || styleGuide.header_1_letter_spacing}
                    handleChange={(value) => handleChange(['header_1', 'mobile', 'letter_spacing'], value)}
                  />
                  <MarginField
                    name="header_1_margin_mobile"
                    value={styleGuide.header_1_margin_mobile || styleGuide.header_1_margin}
                    handleChange={(value) => handleChange(['header_1', 'mobile', 'margin'], value)}
                  />
                </>
              )}
            </div>
          </section>
        </article>

        {/* Header 2 */}
        <article className={styles.StyleGuideEditor}>
          <section className={styles.styleSection}>
            <div className={styles.styleSectionHeader}>
              <p>H2</p>
              <div className={styles.bgToggles}>
                <div className={styles.background}>
                  <span
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.header_2 === 'light',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, header_2: 'light' })}
                  >
                    Light
                  </span>
                  <span
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.header_2 === 'dark',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, header_2: 'dark' })}
                  >
                    Dark
                  </span>
                </div>

                <div className={styles.device}>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, header_2: 'light' })}
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.header_2 === 'light',
                    })}
                  >
                    <SvgIconDesktopThumbnail />
                  </span>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, header_2: 'mobile' })}
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.header_2 === 'mobile',
                    })}
                  >
                    <SvgIconMobileThumbnail />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`sriracha ${
                  activeBackground.header_2 === 'dark'
                    ? 'dark-bg h2'
                    : activeBackground.header_2 === 'mobile'
                    ? 'mobile h2'
                    : ''
                }`}
                style={{
                  padding: activeBackground.header_2 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                  backgroundColor: activeBackground.header_2 === 'dark' ? darkBgColor.code : 'inherit',
                }}
              >
                <h2>Section header</h2>
              </div>
            </div>
            <div className={styles.styleSectionOptions}>
              {activeBackground.header_2 === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.header_2_font_family_index]}
                      value={fontFamilyOptions[styleGuide.header_2_font_family_index]}
                      onChange={(option: FontOptions) => handleChange(['header_2', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight: FontOptions) => weight.value === Number(styleGuide.header_2_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight: FontOptions) => weight.value === Number(styleGuide.header_2_font_weight)
                      )}
                      onChange={(option: FontOptions) => handleChange(['header_2', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="header_2_font_size"
                      value={styleGuide.header_2_font_size}
                      min="0.8"
                      max="10"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_2', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="header_2_line_height"
                      value={styleGuide.header_2_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_2', 'line_height'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.header_2_text_transform}
                    handleChange={(value) => handleChange(['header_2', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="header_2_letter_spacing"
                    value={styleGuide.header_2_letter_spacing}
                    handleChange={(value) => handleChange(['header_2', 'letter_spacing'], value)}
                  />
                  <MarginField
                    name="header_2_margin"
                    value={styleGuide.header_2_margin}
                    handleChange={(value) => handleChange(['header_2', 'margin'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_2_color"
                      onChange={(value: ColorOptions) => handleChange(['header_2', 'color'], value)}
                      value={styleGuide.header_2_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['header_2', 'color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.header_2 === 'dark' ? (
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name="header_2_dark_bg_color"
                    onChange={(value: ColorOptions) => handleChange(['header_2', 'dark_bg_color'], value)}
                    value={styleGuide.header_2_dark_bg_color}
                    placeholder="Color"
                    handleColorSelection={(value: ColorOptions) => handleChange(['header_2', 'dark_bg_color'], value)}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="header_2_font_size_mobile"
                      value={styleGuide.header_2_font_size_mobile}
                      min="0.8"
                      max="10"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_2', 'mobile', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="header_2_line_height_mobile"
                      value={styleGuide.header_2_line_height_mobile || styleGuide.header_2_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_2', 'mobile', 'line_height'])}
                    />
                  </div>
                  <LetterSpacingField
                    name="header_2_letter_spacing_mobile"
                    value={styleGuide.header_2_letter_spacing_mobile || styleGuide.header_2_letter_spacing}
                    handleChange={(value) => handleChange(['header_2', 'mobile', 'letter_spacing'], value)}
                  />
                  <MarginField
                    name="header_2_margin_mobile"
                    value={styleGuide.header_2_margin_mobile || styleGuide.header_2_margin}
                    handleChange={(value) => handleChange(['header_2', 'mobile', 'margin'], value)}
                  />
                </>
              )}
            </div>
          </section>
        </article>

        {/* Header 3 */}
        <article className={styles.StyleGuideEditor}>
          <section className={styles.styleSection}>
            <div className={styles.styleSectionHeader}>
              <p>H3</p>
              <div className={styles.bgToggles}>
                <div className={styles.background}>
                  <span
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.header_3 === 'light',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, header_3: 'light' })}
                  >
                    Light
                  </span>
                  <span
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.header_3 === 'dark',
                    })}
                    onClick={() => setActiveBackground({ ...activeBackground, header_3: 'dark' })}
                  >
                    Dark
                  </span>
                </div>

                <div className={styles.device}>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, header_3: 'light' })}
                    className={classnames(styles.lightBackground, {
                      [styles.active]: activeBackground.header_3 === 'light',
                    })}
                  >
                    <SvgIconDesktopThumbnail />
                  </span>
                  <span
                    onClick={() => setActiveBackground({ ...activeBackground, header_3: 'mobile' })}
                    className={classnames(styles.darkBackground, {
                      [styles.active]: activeBackground.header_3 === 'mobile',
                    })}
                  >
                    <SvgIconMobileThumbnail />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`sriracha ${
                  activeBackground.header_3 === 'dark'
                    ? 'dark-bg h3'
                    : activeBackground.header_3 === 'mobile'
                    ? 'mobile h3'
                    : ''
                }`}
                style={{
                  padding: activeBackground.header_3 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                  backgroundColor: activeBackground.header_3 === 'dark' ? darkBgColor.code : 'inherit',
                }}
              >
                <h3>Callout header</h3>
              </div>
            </div>
            <div className={styles.styleSectionOptions}>
              {activeBackground.header_3 === 'light' ? (
                <>
                  <div className={styles.styleOption}>
                    <span>Font family</span>
                    <Select
                      options={fontFamilyOptions}
                      defaultValue={fontFamilyOptions[styleGuide.header_3_font_family_index]}
                      value={fontFamilyOptions[styleGuide.header_3_font_family_index]}
                      onChange={(option: FontOptions) => handleChange(['header_3', 'font_family_index'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font Weight</span>
                    <Select
                      options={fontWeightOptions}
                      defaultValue={fontWeightOptions.find(
                        (weight: FontOptions) => weight.value === Number(styleGuide.header_3_font_weight)
                      )}
                      value={fontWeightOptions.find(
                        (weight: FontOptions) => weight.value === Number(styleGuide.header_3_font_weight)
                      )}
                      onChange={(option: FontOptions) => handleChange(['header_3', 'font_weight'], option.value)}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="header_3_font_size"
                      value={styleGuide.header_3_font_size}
                      min="0.8"
                      max="10"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_3', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="header_3_line_height"
                      value={styleGuide.header_3_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_3', 'line_height'])}
                    />
                  </div>
                  <CapitalizeSelect
                    value={styleGuide.header_3_text_transform}
                    handleChange={(value) => handleChange(['header_3', 'text_transform'], value)}
                  />
                  <LetterSpacingField
                    name="header_3_letter_spacing"
                    value={styleGuide.header_3_letter_spacing}
                    handleChange={(value) => handleChange(['header_3', 'letter_spacing'], value)}
                  />
                  <MarginField
                    name="header_3_margin"
                    value={styleGuide.header_3_margin}
                    handleChange={(value) => handleChange(['header_3', 'margin'], value)}
                  />
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_3_color"
                      onChange={(value: ColorOptions) => handleChange(['header_3', 'color'], value)}
                      value={styleGuide.header_3_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['header_3', 'color'], value)}
                    />
                  </div>
                </>
              ) : activeBackground.header_3 === 'dark' ? (
                <div className={styles.styleOption}>
                  <span>Color</span>
                  <ColorSelector
                    name="header_3_dark_bg_color"
                    onChange={(value: ColorOptions) => handleChange(['header_3', 'dark_bg_color'], value)}
                    value={styleGuide.header_3_dark_bg_color}
                    placeholder="Color"
                    handleColorSelection={(value: ColorOptions) => handleChange(['header_3', 'dark_bg_color'], value)}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.styleOption}>
                    <span>Font size</span>
                    <input
                      type="number"
                      name="header_3_font_size_mobile"
                      value={styleGuide.header_3_font_size_mobile}
                      min="0.8"
                      max="10"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_3', 'mobile', 'font_size'])}
                    />
                  </div>
                  <div className={styles.styleOption}>
                    <span>Line height</span>
                    <input
                      type="number"
                      name="header_3_line_height_mobile"
                      value={styleGuide.header_3_line_height_mobile || styleGuide.header_3_line_height}
                      min="0.8"
                      max="2"
                      step="0.1"
                      onChange={(e) => handleChangeEvent(e, ['header_3', 'mobile', 'line_height'])}
                    />
                  </div>
                  <LetterSpacingField
                    name="header_3_letter_spacing_mobile"
                    value={styleGuide.header_3_letter_spacing_mobile || styleGuide.header_3_letter_spacing}
                    handleChange={(value) => handleChange(['header_3', 'mobile', 'letter_spacing'], value)}
                  />
                  <MarginField
                    name="header_3_margin_mobile"
                    value={styleGuide.header_3_margin_mobile || styleGuide.header_3_margin}
                    handleChange={(value) => handleChange(['header_3', 'mobile', 'margin'], value)}
                  />
                </>
              )}
            </div>
          </section>
        </article>

        {/* Header 4 */}
        {styleGuide.header_count > 3 && (
          <article className={styles.StyleGuideEditor}>
            <section className={styles.styleSection} ref={myRef4}>
              <div className={styles.styleSectionHeader}>
                <p>H4</p>
                <div className={styles.controlsContainer}>
                  <div className={styles.bgToggles}>
                    <div className={styles.background}>
                      <span
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.header_4 === 'light',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, header_4: 'light' })}
                      >
                        Light
                      </span>
                      <span
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.header_4 === 'dark',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, header_4: 'dark' })}
                      >
                        Dark
                      </span>
                    </div>

                    <div className={styles.device}>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, header_4: 'light' })}
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.header_4 === 'light',
                        })}
                      >
                        <SvgIconDesktopThumbnail />
                      </span>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, header_4: 'mobile' })}
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.header_4 === 'mobile',
                        })}
                      >
                        <SvgIconMobileThumbnail />
                      </span>
                    </div>
                  </div>
                  {styleGuide.header_count === 4 && (
                    <div
                      className={styles.deleteIcon}
                      onClick={() => {
                        handleChange(['header_count'], styleGuide.header_count - 1);
                      }}
                    >
                      <SvgIconTrash />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div
                  className={`sriracha ${
                    activeBackground.header_4 === 'dark'
                      ? 'dark-bg h4'
                      : activeBackground.header_4 === 'mobile'
                      ? 'mobile h4'
                      : ''
                  }`}
                  style={{
                    padding: activeBackground.header_4 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                    backgroundColor: activeBackground.header_4 === 'dark' ? darkBgColor.code : 'inherit',
                  }}
                >
                  <h4>Heading 4</h4>
                </div>
              </div>
              <div className={styles.styleSectionOptions}>
                {activeBackground.header_4 === 'light' ? (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font family</span>
                      <Select
                        options={fontFamilyOptions}
                        defaultValue={fontFamilyOptions[styleGuide.header_4_font_family_index]}
                        value={fontFamilyOptions[styleGuide.header_4_font_family_index]}
                        onChange={(option: FontOptions) =>
                          handleChange(['header_4', 'font_family_index'], option.value)
                        }
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font Weight</span>
                      <Select
                        options={fontWeightOptions}
                        defaultValue={fontWeightOptions.find(
                          (weight: FontOptions) => weight.value === Number(styleGuide.header_4_font_weight)
                        )}
                        value={fontWeightOptions.find(
                          (weight: FontOptions) => weight.value === Number(styleGuide.header_4_font_weight)
                        )}
                        onChange={(option: FontOptions) => handleChange(['header_4', 'font_weight'], option.value)}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="header_4_font_size"
                        value={styleGuide.header_4_font_size}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_4', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="header_4_line_height"
                        value={styleGuide.header_4_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_4', 'line_height'])}
                      />
                    </div>
                    <CapitalizeSelect
                      value={styleGuide.header_4_text_transform}
                      handleChange={(value) => handleChange(['header_4', 'text_transform'], value)}
                    />
                    <LetterSpacingField
                      name="header_4_letter_spacing"
                      value={styleGuide.header_4_letter_spacing}
                      handleChange={(value) => handleChange(['header_4', 'letter_spacing'], value)}
                    />
                    <MarginField
                      name="header_4_margin"
                      value={styleGuide.header_4_margin}
                      handleChange={(value) => handleChange(['header_4', 'margin'], value)}
                    />
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="header_4_color"
                        onChange={(value: ColorOptions) => handleChange(['header_4', 'color'], value)}
                        value={styleGuide.header_4_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) => handleChange(['header_4', 'color'], value)}
                      />
                    </div>
                  </>
                ) : activeBackground.header_4 === 'dark' ? (
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_4_dark_bg_color"
                      onChange={(value: ColorOptions) => handleChange(['header_4', 'dark_bg_color'], value)}
                      value={styleGuide.header_4_dark_bg_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['header_4', 'dark_bg_color'], value)}
                    />
                  </div>
                ) : (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="header_4_font_size_mobile"
                        value={styleGuide.header_4_font_size_mobile}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_4', 'mobile', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="header_4_line_height_mobile"
                        value={styleGuide.header_4_line_height_mobile || styleGuide.header_4_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_4', 'mobile', 'line_height'])}
                      />
                    </div>
                    <LetterSpacingField
                      name="header_4_letter_spacing_mobile"
                      value={styleGuide.header_4_letter_spacing_mobile || styleGuide.header_4_letter_spacing}
                      handleChange={(value) => handleChange(['header_4', 'mobile', 'letter_spacing'], value)}
                    />
                    <MarginField
                      name="header_4_margin_mobile"
                      value={styleGuide.header_4_margin_mobile || styleGuide.header_4_margin}
                      handleChange={(value) => handleChange(['header_4', 'mobile', 'margin'], value)}
                    />
                  </>
                )}
              </div>
            </section>
          </article>
        )}

        {/* Header 5 */}
        {styleGuide.header_count > 4 && (
          <article className={styles.StyleGuideEditor}>
            <section className={styles.styleSection} ref={myRef5}>
              <div className={styles.styleSectionHeader}>
                <p>H5</p>
                <div className={styles.controlsContainer}>
                  <div className={styles.bgToggles}>
                    <div className={styles.background}>
                      <span
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.header_5 === 'light',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, header_5: 'light' })}
                      >
                        Light
                      </span>
                      <span
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.header_5 === 'dark',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, header_5: 'dark' })}
                      >
                        Dark
                      </span>
                    </div>

                    <div className={styles.device}>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, header_5: 'light' })}
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.header_5 === 'light',
                        })}
                      >
                        <SvgIconDesktopThumbnail />
                      </span>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, header_5: 'mobile' })}
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.header_5 === 'mobile',
                        })}
                      >
                        <SvgIconMobileThumbnail />
                      </span>
                    </div>
                  </div>
                  {styleGuide.header_count === 5 && (
                    <div
                      className={styles.deleteIcon}
                      onClick={() => {
                        handleChange(['header_count'], styleGuide.header_count - 1);
                      }}
                    >
                      <SvgIconTrash />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div
                  className={`sriracha ${
                    activeBackground.header_5 === 'dark'
                      ? 'dark-bg h5'
                      : activeBackground.header_5 === 'mobile'
                      ? 'mobile h5'
                      : ''
                  }`}
                  style={{
                    padding: activeBackground.header_5 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                    backgroundColor: activeBackground.header_5 === 'dark' ? darkBgColor.code : 'inherit',
                  }}
                >
                  <h5>Heading 5</h5>
                </div>
              </div>
              <div className={styles.styleSectionOptions}>
                {activeBackground.header_5 === 'light' ? (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font family</span>
                      <Select
                        options={fontFamilyOptions}
                        defaultValue={fontFamilyOptions[styleGuide.header_5_font_family_index]}
                        value={fontFamilyOptions[styleGuide.header_5_font_family_index]}
                        onChange={(option: FontOptions) =>
                          handleChange(['header_5', 'font_family_index'], option.value)
                        }
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font Weight</span>
                      <Select
                        options={fontWeightOptions}
                        defaultValue={fontWeightOptions.find(
                          (weight: FontOptions) => weight.value === Number(styleGuide.header_5_font_weight)
                        )}
                        value={fontWeightOptions.find(
                          (weight: FontOptions) => weight.value === Number(styleGuide.header_5_font_weight)
                        )}
                        onChange={(option: FontOptions) => handleChange(['header_5', 'font_weight'], option.value)}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="header_5_font_size"
                        value={styleGuide.header_5_font_size}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_5', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="header_5_line_height"
                        value={styleGuide.header_5_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_5', 'line_height'])}
                      />
                    </div>
                    <CapitalizeSelect
                      value={styleGuide.header_5_text_transform}
                      handleChange={(value) => handleChange(['header_5', 'text_transform'], value)}
                    />
                    <LetterSpacingField
                      name="header_5_letter_spacing"
                      value={styleGuide.header_5_letter_spacing}
                      handleChange={(value) => handleChange(['header_5', 'letter_spacing'], value)}
                    />
                    <MarginField
                      name="header_5_margin"
                      value={styleGuide.header_5_margin}
                      handleChange={(value) => handleChange(['header_5', 'margin'], value)}
                    />
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="header_5_color"
                        onChange={(value: ColorOptions) => handleChange(['header_5', 'color'], value)}
                        value={styleGuide.header_5_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) => handleChange(['header_5', 'color'], value)}
                      />
                    </div>
                  </>
                ) : activeBackground.header_5 === 'dark' ? (
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_5_dark_bg_color"
                      onChange={(value: ColorOptions) => handleChange(['header_5', 'dark_bg_color'], value)}
                      value={styleGuide.header_5_dark_bg_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['header_5', 'dark_bg_color'], value)}
                    />
                  </div>
                ) : (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="header_5_font_size_mobile"
                        value={styleGuide.header_5_font_size_mobile}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_5', 'mobile', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="header_5_line_height_mobile"
                        value={styleGuide.header_5_line_height_mobile || styleGuide.header_5_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_5', 'mobile', 'line_height'])}
                      />
                    </div>
                    <LetterSpacingField
                      name="header_5_letter_spacing_mobile"
                      value={styleGuide.header_5_letter_spacing_mobile || styleGuide.header_5_letter_spacing}
                      handleChange={(value) => handleChange(['header_5', 'mobile', 'letter_spacing'], value)}
                    />
                    <MarginField
                      name="header_5_margin_mobile"
                      value={styleGuide.header_5_margin_mobile || styleGuide.header_5_margin}
                      handleChange={(value) => handleChange(['header_5', 'mobile', 'margin'], value)}
                    />
                  </>
                )}
              </div>
            </section>
          </article>
        )}

        {/* Header 6 */}
        {styleGuide.header_count > 5 && (
          <article className={styles.StyleGuideEditor}>
            <section className={styles.styleSection} ref={myRef6}>
              <div className={styles.styleSectionHeader}>
                <p>H6</p>
                <div className={styles.controlsContainer}>
                  <div className={styles.bgToggles}>
                    <div className={styles.background}>
                      <span
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.header_6 === 'light',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, header_6: 'light' })}
                      >
                        Light
                      </span>
                      <span
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.header_6 === 'dark',
                        })}
                        onClick={() => setActiveBackground({ ...activeBackground, header_6: 'dark' })}
                      >
                        Dark
                      </span>
                    </div>

                    <div className={styles.device}>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, header_6: 'light' })}
                        className={classnames(styles.lightBackground, {
                          [styles.active]: activeBackground.header_6 === 'light',
                        })}
                      >
                        <SvgIconDesktopThumbnail />
                      </span>
                      <span
                        onClick={() => setActiveBackground({ ...activeBackground, header_6: 'mobile' })}
                        className={classnames(styles.darkBackground, {
                          [styles.active]: activeBackground.header_6 === 'mobile',
                        })}
                      >
                        <SvgIconMobileThumbnail />
                      </span>
                    </div>
                  </div>
                  {styleGuide.header_count === 6 && (
                    <div
                      className={styles.deleteIcon}
                      onClick={() => {
                        handleChange(['header_count'], styleGuide.header_count - 1);
                      }}
                    >
                      <SvgIconTrash />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div
                  className={`sriracha ${
                    activeBackground.header_6 === 'dark'
                      ? 'dark-bg h6'
                      : activeBackground.header_6 === 'mobile'
                      ? 'mobile h6'
                      : ''
                  }`}
                  style={{
                    padding: activeBackground.header_6 === 'dark' ? '1rem 2rem 0.5rem' : '0',
                    backgroundColor: activeBackground.header_6 === 'dark' ? darkBgColor.code : 'inherit',
                  }}
                >
                  <h6>Heading 6</h6>
                </div>
              </div>
              <div className={styles.styleSectionOptions}>
                {activeBackground.header_6 === 'light' ? (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font family</span>
                      <Select
                        options={fontFamilyOptions}
                        defaultValue={fontFamilyOptions[styleGuide.header_6_font_family_index]}
                        value={fontFamilyOptions[styleGuide.header_6_font_family_index]}
                        onChange={(option: FontOptions) =>
                          handleChange(['header_6', 'font_family_index'], option.value)
                        }
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font Weight</span>
                      <Select
                        options={fontWeightOptions}
                        defaultValue={fontWeightOptions.find(
                          (weight: FontOptions) => weight.value === Number(styleGuide.header_6_font_weight)
                        )}
                        value={fontWeightOptions.find(
                          (weight: FontOptions) => weight.value === Number(styleGuide.header_6_font_weight)
                        )}
                        onChange={(option: FontOptions) => handleChange(['header_6', 'font_weight'], option.value)}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="header_6_font_size"
                        value={styleGuide.header_6_font_size}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_6', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="header_6_line_height"
                        value={styleGuide.header_6_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_6', 'line_height'])}
                      />
                    </div>
                    <CapitalizeSelect
                      value={styleGuide.header_6_text_transform}
                      handleChange={(value) => handleChange(['header_6', 'text_transform'], value)}
                    />
                    <LetterSpacingField
                      name="header_6_letter_spacing"
                      value={styleGuide.header_6_letter_spacing}
                      handleChange={(value) => handleChange(['header_6', 'letter_spacing'], value)}
                    />
                    <MarginField
                      name="header_6_margin"
                      value={styleGuide.header_6_margin}
                      handleChange={(value) => handleChange(['header_6', 'margin'], value)}
                    />
                    <div className={styles.styleOption}>
                      <span>Color</span>
                      <ColorSelector
                        name="header_6_color"
                        onChange={(value: ColorOptions) => handleChange(['header_6', 'color'], value)}
                        value={styleGuide.header_6_color}
                        placeholder="Color"
                        handleColorSelection={(value: ColorOptions) => handleChange(['header_6', 'color'], value)}
                      />
                    </div>
                  </>
                ) : activeBackground.header_6 === 'dark' ? (
                  <div className={styles.styleOption}>
                    <span>Color</span>
                    <ColorSelector
                      name="header_6_dark_bg_color"
                      onChange={(value: ColorOptions) => handleChange(['header_6', 'dark_bg_color'], value)}
                      value={styleGuide.header_6_dark_bg_color}
                      placeholder="Color"
                      handleColorSelection={(value: ColorOptions) => handleChange(['header_6', 'dark_bg_color'], value)}
                    />
                  </div>
                ) : (
                  <>
                    <div className={styles.styleOption}>
                      <span>Font size</span>
                      <input
                        type="number"
                        name="header_6_font_size_mobile"
                        value={styleGuide.header_6_font_size_mobile}
                        min="0.8"
                        max="10"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_6', 'mobile', 'font_size'])}
                      />
                    </div>
                    <div className={styles.styleOption}>
                      <span>Line height</span>
                      <input
                        type="number"
                        name="header_6_line_height_mobile"
                        value={styleGuide.header_6_line_height_mobile || styleGuide.header_6_line_height}
                        min="0.8"
                        max="2"
                        step="0.1"
                        onChange={(e) => handleChangeEvent(e, ['header_6', 'mobile', 'line_height'])}
                      />
                    </div>
                    <LetterSpacingField
                      name="header_6_letter_spacing_mobile"
                      value={styleGuide.header_6_letter_spacing_mobile || styleGuide.header_6_letter_spacing}
                      handleChange={(value) => handleChange(['header_6', 'mobile', 'letter_spacing'], value)}
                    />
                    <MarginField
                      name="header_6_margin_mobile"
                      value={styleGuide.header_6_margin_mobile || styleGuide.header_6_margin}
                      handleChange={(value) => handleChange(['header_6', 'mobile', 'margin'], value)}
                    />
                  </>
                )}
              </div>
            </section>
          </article>
        )}
      </>
    );
  }
);
