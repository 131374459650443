import React, { useRef, useState } from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import { connect, useSelector } from 'react-redux';

import { set } from '../../../lib/immutable-operations/operations';

import { Checkbox } from '../../Checkbox';

import withSiteProps from '../../../containers/withSiteProps';
import withSiteId from '../../../containers/withSiteId';
import * as siteSelectors from '../../../reducers/sitesReducer';
import { selectHasPermission } from '../../../reducers/policyReducer';
import { selectHasSubscription } from '../../../reducers/subscriptionReducer';
import { selectPartner } from '../../../reducers/accountReducer';
import * as blogSelectors from '../../../reducers/blogsReducer';
import { updateWithOps as updateSite } from '../../../actions/siteActions';
import * as uiSubscriptionManagerActions from '../../../actions/uiSubscriptionManagerActions';
import CheckPermissionOnClick from '../../SubscriptionManager/CheckPermissionOnClick';

import { assembleUrl } from '../../../services/spark-api';

import styles from './AdvancedSEO.module.scss';

function XMLFileHandler({ hasPermission, openSubscriptionManager, siteId }) {
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadLink = useRef(null);
  const [error, setError] = useState();
  const uploaderRef = useRef(null);
  const blogs = useSelector(blogSelectors.selectAll);

  const downloadXML = (blog) => {
    setIsDownloading(true);
    const url = assembleUrl(`item/${blog.id}/wp_xml_export/?site_id=${siteId}`);

    const options = {
      method: 'GET',
      headers: {
        Authorization: `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`,
      },
    };
    fetch(url, options)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        if (downloadLink.current) {
          downloadLink.current.href = url;
          downloadLink.current.download = 'content.xml';
          downloadLink.current.click();
          window.URL.revokeObjectURL(url);
        }
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const uploadXML = (file) => {
    setIsUploading(true);
    const url = assembleUrl(`item/wp_xml_import/?site_id=${siteId}`);

    const data = new FormData();
    data.append('file', file);

    const options = {
      method: 'POST',
      headers: {
        Authorization: `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`,
        'content-type': 'application/xml',
      },
      body: file,
    };

    fetch(url, options)
      .then((response) => {
        if (response.status === 406) {
          response.json().then((body) => {
            if (body.error) {
              setError(`XML import failed, please try again ${body.error}`);
            } else {
              setError('There was an error while uploading XML');
            }
          });
        } else return response.json();
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <CheckPermissionOnClick
      action="Item:wp_xml_export"
      onClick={() => {}}
      render={(handleClick, isPermitted) => (
        <div className={styles.row}>
          <div className={styles.label}>
            <span>Export blog posts XML or upload XML to generate posts</span>
          </div>
          {isPermitted ? (
            <div className={styles.links}>
              {error && <small className={styles.errors}>{error}</small>}
              <a
                onClick={
                  isUploading
                    ? () => {}
                    : () => {
                        if (uploaderRef.current) {
                          uploaderRef.current.click();
                          setError();
                        }
                      }
                }
                rel="noopener noreferrer"
              >
                {isUploading ? 'Uploading...' : 'Import XML'}
                <form style={{ display: 'none' }}>
                  <input
                    className="fileInput"
                    ref={uploaderRef}
                    type="file"
                    onChange={(e) => {
                      e.preventDefault();

                      const file = e.target.files[0];
                      uploadXML(file);
                    }}
                    accept=".xml"
                  />
                </form>
              </a>
              <a
                onClick={() => {
                  blogs.length && downloadXML(blogs[0]);
                }}
                rel="noopener noreferrer"
              >
                {isDownloading ? 'Downloading...' : 'Export XML'}
              </a>
              <a ref={downloadLink} style={{ display: 'none' }}>
                Download
              </a>
            </div>
          ) : (
            <div className={styles.upgradeButton}>
              <button
                className="button button-primary button-sm"
                style={{ margin: 0 }}
                onClick={() =>
                  openSubscriptionManager({
                    requestedAction: 'Item:wp_xml_export',
                  })
                }
              >
                Upgrade
              </button>
            </div>
          )}
        </div>
      )}
    />
  );
}

function CSVFileDownloader({ hasPermission, openSubscriptionManager, siteId }) {
  const canDownloadContacts = hasPermission('Contact:download', 'subscription');
  const downloadLink = useRef(null);

  const downloadContactsCsv = () => {
    const url = assembleUrl(`contact/download/?site_id=${siteId}`);

    const options = {
      method: 'GET',
      headers: {
        Authorization: `JWT ${window.sprkfe.storage.getItem('JWT-TOKEN')}`,
      },
    };

    return fetch(url, options)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        if (downloadLink.current) {
          downloadLink.current.href = url;
          downloadLink.current.download = 'customers.csv';
          downloadLink.current.click();
          window.URL.revokeObjectURL(url);
        }
      });
  };

  return (
    <div className={styles.row}>
      <div className={styles.label}>
        <span>Export your Elastic Path Studio customers to CSV</span>
      </div>
      {canDownloadContacts ? (
        <div className={styles.links}>
          <a href="#" onClick={downloadContactsCsv} rel="noopener noreferrer">
            Export data
          </a>
          <a ref={downloadLink} style={{ display: 'none' }}>
            Download
          </a>
        </div>
      ) : (
        <div className={styles.upgradeButton}>
          <button
            className="button button-primary button-sm"
            style={{ margin: 0 }}
            onClick={() =>
              openSubscriptionManager({
                requestedAction: 'Contact:download',
              })
            }
          >
            Upgrade
          </button>
        </div>
      )}
    </div>
  );
}

function mapSiteToProps(site) {
  return {
    siteMapUrl: siteSelectors.getSiteMapUrlFromSite(site),
    siteTagDynamicPages: siteSelectors.getIsDynamicTagsPages(site),
  };
}

function mapStateToProps(state) {
  return {
    hasSubscription: selectHasSubscription(state),
    hasPermission: selectHasPermission(state),
    partner: selectPartner(state),
  };
}

const mapDispatchToProps = {
  openSubscriptionManager: uiSubscriptionManagerActions.open,
  updateSite: updateSite,
};

class Sitemap extends React.PureComponent {
  uploaderRef = React.createRef();

  render() {
    const { siteMapUrl, openSubscriptionManager, hasPermission, siteTagDynamicPages, updateSite, partner } = this.props;

    const hasAmpPermission = hasPermission('AMP', 'subscription');

    return (
      <section id="sitemap" className={styles.sitemap}>
        <header>
          <h1>Advance SEO Features</h1>
        </header>
        <CheckPermissionOnClick
          action="Site:sitemap"
          onClick={() => {}}
          render={(handleClick, isPermitted) => (
            <div className={classnames(styles.row, styles.firstRow)}>
              <div className={styles.label}>
                <span>Automatic XML sitemap for Google Search Console</span>
              </div>
              {isPermitted ? (
                <div className={styles.links}>
                  <a target="_blank" href={siteMapUrl}>
                    View Sitemap
                  </a>
                  <a
                    href="https://support.google.com/webmasters/answer/7451001"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Submitting to Google
                  </a>
                </div>
              ) : (
                <div className={styles.upgradeButton}>
                  <button
                    className="button button-primary button-sm"
                    style={{ margin: 0 }}
                    onClick={() =>
                      openSubscriptionManager({
                        requestedAction: 'Site:sitemap',
                      })
                    }
                  >
                    Upgrade
                  </button>
                </div>
              )}
            </div>
          )}
        />

        <XMLFileHandler
          hasPermission={hasPermission}
          openSubscriptionManager={openSubscriptionManager}
          siteId={this.props.siteId}
        />

        <CSVFileDownloader
          hasPermission={hasPermission}
          openSubscriptionManager={openSubscriptionManager}
          siteId={this.props.siteId}
        />

        {hasPermission('AMP', 'role') && (hasPermission('AMP', 'subscription') || !partner) && (
          <div className={styles.row}>
            <div
              className={classnames(styles.label, {
                [styles.isDisabled]: !hasAmpPermission,
              })}
            >
              <Checkbox checked={hasAmpPermission} />
              <span>Enable AMP pages for your blog posts</span>
            </div>
            <div className={styles.links}>
              <a target="_blank" href="https://www.unstack.com/goto?amp-blog">
                View help docs
              </a>
              {!hasAmpPermission && (
                <button
                  className="button button-primary button-sm"
                  style={{ margin: 0 }}
                  onClick={() =>
                    openSubscriptionManager({
                      requestedAction: 'AMP:*',
                    })
                  }
                >
                  Upgrade
                </button>
              )}
            </div>
          </div>
        )}

        <CheckPermissionOnClick
          action="Tag"
          onClick={() => {}}
          render={(handleClick, isPermitted) => (
            <div className={styles.row}>
              <div
                className={classnames(styles.label, {
                  [styles.isDisabled]: !isPermitted,
                })}
              >
                <Checkbox
                  onClick={
                    isPermitted
                      ? () => {
                          updateSite(set('metadata.tag_dynamic_pages_enabled', !siteTagDynamicPages));
                        }
                      : () => {}
                  }
                  checked={siteTagDynamicPages}
                />
                <span>Generate dynamic pages for tags</span>
              </div>
              <div className={styles.upgradeButton}>
                {!isPermitted && (
                  <button
                    className="button button-primary button-sm"
                    style={{ margin: 0 }}
                    onClick={() =>
                      openSubscriptionManager({
                        requestedAction: 'Tag',
                      })
                    }
                  >
                    Upgrade
                  </button>
                )}
              </div>
            </div>
          )}
        />
      </section>
    );
  }
}

export default compose(
  withSiteId,
  withSiteProps(mapSiteToProps),
  connect(mapStateToProps, mapDispatchToProps)
)(Sitemap);
