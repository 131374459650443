import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge/utilities';

import isInIFrame from 'lib/isInIFrame';

import * as integrationActions from 'actions/integrationActions';
import { SHOPIFY_CLIENT_ID } from 'settings';

import UnstackSplash from 'components/UnstackSplash';

import Spinner from 'components/base/Spinner';

import { ReactComponent as SvgIconEP } from '../../../../assets/images/elastic-path-mark.svg';
import { ReactComponent as SvgIconShopify } from '../../../../assets/images/icon-shopify.svg';
import { ReactComponent as SvgIconAdd } from '../../../../assets/images/icon-plus.svg';

import InAppView from 'lazy/InAppView';

import styles from '../../Integrations.module.scss';
import { OnMount } from 'hooks/mountUnmountHooks';
import { storeToken } from 'services/spark-auth';
import { useNavigate } from 'react-router-dom';

const PolarisStyle = React.lazy(() => import('./PolarisStyleModule'));

const PolarisStyleProvider = ({ children }: any) => {
  return (
    <React.Suspense fallback={<></>}>
      {IS_IN_IFRAME && (
        <>
          <PolarisStyle />
          {children}
        </>
      )}
    </React.Suspense>
  );
};

const IS_IN_IFRAME = isInIFrame();

export default function () {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [token, setToken] = useState(null);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const basePath = location.pathname.split('/')[1];

  OnMount(() => {
    getShopifyAuth();
  });

  function getShopifyAuth() {
    const host = new URLSearchParams(document.location.search).get('host');
    const authPromise = new Promise(async (res, rej) => {
      let sessionToken = undefined;
      if (IS_IN_IFRAME) {
        const app = createApp({
          apiKey: SHOPIFY_CLIENT_ID,
          host,
        });
        sessionToken = await getSessionToken(app);

        dispatch(
          integrationActions.shopifyAppAuth({ search, isInIframe: IS_IN_IFRAME, basePath }, res, rej, sessionToken)
        );
      } else {
        dispatch(
          integrationActions.shopifyAppAuth({ search, isInIframe: IS_IN_IFRAME, basePath }, res, rej, sessionToken)
        );
      }
    });
    authPromise.then((res: any) => {
      connectToShopify(res);
    });
  }

  function connectToShopify(res: any) {
    const { status, url, token, site, site_integration: integration, shop, user, subscription, redirect_url } = res;

    if (!IS_IN_IFRAME) {
      if (status === 201 || !status) window.location = url || redirect_url;
      else if (status === 400) navigate(`/${basePath}/failure`, { replace: true });
      else if (status === 409) navigate('/', { replace: true });
      else navigate(`/${basePath}/subscribe?subdomain=${site?.subdomain}`, { replace: true });
    } else {
      storeToken(token);
      setToken(token);
      setData({ site, token, integration, shop, user, subscription });
    }
  }

  return IS_IN_IFRAME ? (
    token !== null ? (
      <PolarisStyleProvider>
        <React.Suspense fallback={<div />}>
          <InAppView
            updateData={(key: string, value: any) => setData({ ...data, [key]: value })}
            token={token}
            {...data}
          />
        </React.Suspense>
      </PolarisStyleProvider>
    ) : (
      <div>
        <UnstackSplash isLoading />
      </div>
    )
  ) : (
    <div className={styles.popup}>
      <div className={styles.logos}>
        <SvgIconEP className={styles.elasticpath} />
        <SvgIconAdd className={styles.add} />
        <SvgIconShopify className={styles.shopify} />
      </div>
      <p className={styles.title}>Setting up Elastic Path Studio</p>
      <p className={styles.instructions}>
        Do not close this window. You will be redirected to Elastic Path Studio when this process completes.
      </p>
      <div className={styles.spinner}>
        <Spinner className="fixed" />
      </div>
    </div>
  );
}
