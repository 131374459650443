import { UPluginManager } from "../plugins/utils/UPluginManager";

export class Sprk {
  public static on(e: string, f: (data: any) => any, uc?: any) {
    (window as any).sprk.on(null, e, f, uc);
  }
  public static trigger(e: string, d?: any) {
    if((window as any).sprk) {
      return (window as any).sprk.trigger(e, d);
    }
  }
  public static getPluginManager() {
    return (window as any).sprk.UPluginManager as UPluginManager;
  }
  // for the web app, sprk needs to be initialized
  public static initialize() {
    const sprk = (window as any).sprk || {};
    sprk.document = document;
    sprk.staticPath = '';
    sprk.evs = [];
    sprk.on = function(...args: any) {
      var i = args[0] === '' + args[0] ? 0 : 1,
          e = args[i],
          f = args[i+1],
          uc = args.length > i+2 ? args[i+2] : null;
      sprk.evs[e] = sprk.evs[e] || {
        x: 0, fs: [], ds: []
      };
      sprk.evs[e].fs.push(f);
      if (sprk.evs[e].x) {
        sprk.evs[e].ds.forEach(function(d: any) {
          f(d)
        });
      }
    };
    sprk.trigger = function(e: string, d: any) {
      var ev = sprk.evs[e];
      const prms: Promise<any>[] = [];
      if (ev) {
        if (ev.fs && ev.fs.length) {
          ev.fs.forEach(function(f: any) {
            const resp = f(d);
            if(resp) {
              prms.push(resp);
            }
          })
        }
        ev.ds.push(d);
        sprk.evs[e].x = 1
      } else {
        sprk.evs[e] = {
          x: 1, fs: [], ds: [d]
        }
      }
      return prms;
    };
    (window as any).sprk = sprk;
  }
}