import React, { useState, useRef } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { connect } from 'react-redux';
import get from 'lodash/get';

import useOnClickOutside from 'hooks/useClickOutside';
import { useEscapeKey } from 'hooks/useKeyBinding';
import ScheduleTime from './ScheduleTime';
import { selectAccountIsTrial } from '../../../reducers/accountReducer';
import BackToShopify from './BackToShopify';
import withIntegrations from 'containers/withIntegrations';

import styles from './Footer.module.scss';
import { ReactComponent as LogoSvg } from '../../../assets/images/elastic-path-logo.svg';
import { ReactComponent as SvgIconMenu } from '../../../assets/images/icon-help-menu.svg';
import { ReactComponent as SvgIconKnowledge } from '../../../assets/images/icon-help-menu-knowledge.svg';
import { ReactComponent as SvgIconVote } from '../../../assets/images/icon-star.svg';
import { ReactComponent as SvgIconFeedback } from '../../../assets/images/icon-feedback.svg';
import { ReactComponent as SvgIconContactUs } from '../../../assets/images/icon-contact-us.svg';
import { PARTNERS } from '../../../constants';

function mapStateToProps(state) {
  return {
    partnerID: state.account?.partner?.id,
    partnerReference: state.account?.partner_reference,
  };
}

function NavFooter({ partnerReference, partnerID }) {
  const isShopifyPartner = partnerID === PARTNERS.Shopify || partnerID === PARTNERS.KlevuShopify;
  const ref = useRef();
  const [opened, setOpened] = useState(false);
  const [reference, setReference] = useState(null);
  useOnClickOutside(ref, () => setOpened(false));
  useEscapeKey(() => setOpened(false));
  const [popper, setPopper] = useState(null);
  const { styles: popperStyles } = usePopper(reference, popper, {
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setModalOpen = () => setIsModalOpen(true);
  const setModalClose = () => setIsModalOpen(false);
  const isTrial = useSelector(selectAccountIsTrial);

  function onClick() {
    if (isTrial) {
      setModalOpen();
    } else {
      window.open('https://www.unstack.com/goto?get-help ', '_blank');
    }
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.brand}>
        <LogoSvg width="110px" className={styles.logo} />
        <a onClick={() => setOpened(true)} className={styles.helpMenuButton} data-test-id="choose-template-type">
          <SvgIconMenu height={20} width={20} />
          {opened && (
            <div>
              <div ref={setReference} />
              {createPortal(
                <div ref={setPopper} style={{ ...popperStyles.popper, zIndex: 3 }}>
                  <div ref={ref} className={styles.helpMenu}>
                    <a href="https://www.unstack.com/goto?documentation" target="_blank">
                      <div className={styles.linkIcon}>
                        <SvgIconKnowledge />
                      </div>
                      Knowledge base
                    </a>
                    <a onClick={() => onClick()}>
                      <div className={styles.linkIcon}>
                        <SvgIconContactUs />
                      </div>
                      Contact us
                    </a>
                    <div className={styles.divider} />
                    <a href="https://www.unstack.com/goto?give-feedback" target="_blank">
                      <div className={styles.linkIcon}>
                        <SvgIconFeedback />
                      </div>
                      Give feedback
                    </a>
                    <a href="https://www.unstack.com/goto?slack-community" target="_blank">
                      <div className={styles.linkIcon}>
                        <SvgIconVote />
                      </div>
                      Slack Community
                    </a>
                  </div>
                </div>,
                document.body
              )}
            </div>
          )}
          <ScheduleTime isModalOpen={isModalOpen} setModalClose={setModalClose} setModalOpen={setModalOpen} />
        </a>
      </div>
      {isShopifyPartner && <BackToShopify partnerReference={partnerReference} />}
    </footer>
  );
}

export default connect(mapStateToProps, null)(NavFooter);
